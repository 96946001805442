.mainWrapper {
  width: 50px;
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 100%;
  user-select: none;
  height: 60px;
  cursor: pointer;
}
.mainWrapper p {
  font-family: "Orbitron", sans-serif;
}
