.modalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 30;
  background-color: #00000092;
}
.modal {
  background-color: #f5f5f5;
  min-width: 280px;
  min-height: 120px;
  padding: 15px;
  box-shadow: 0 0 2px rgb(88, 88, 88);
  border-radius: 3px;
}
