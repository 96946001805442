* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  font-family: "Exo 2", sans-serif;
}
html {
  position: relative;
}
*::-webkit-scrollbar {
  position: absolute;
  width: 10px;
  height: 4px;
  background-color: transparent;
}
*::-webkit-scrollbar-thumb {
  background-color: rgb(130, 130, 130);
}
hr {
  margin: 10px 0;
  height: 2px;
  width: 100%;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.App {
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  height: 100vh;
  width: 100%;
  overflow-anchor: none;
  scroll-behavior: smooth;
  background-color: #ececec;
  color: rgb(47, 47, 47);
}
.LoadingScreen {
  position: absolute;
  background-color: #f8f8f8;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30vh;
  z-index: 99;
  text-align: center;
}
.Center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
}
.LoadingWrapper1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.LoadingWrapper2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 80vh;
}
.Window {
  display: flex;
  flex-direction: column;
  background-color: #f8f8f8;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
}
.Toastify__toast-container {
  background: transparent;
}
.Toastify__toast--info {
  background: rgb(30, 51, 135);
}
.Toastify__toast--success {
  background: rgb(58, 169, 58);
}
.Toastify__toast--error {
  background: rgb(169, 60, 60);
}
.ConfirmButtonsContainer {
  display: flex;
  justify-content: space-around;
  padding: 20px 0 5px;
}
