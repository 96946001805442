.DropMenuWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 25;
}
.Invisible,
.Logo {
  visibility: hidden;
}
.MainContainer {
  width: 100%;
  margin: 0 auto;
  display: flex;
  max-width: 1200px;
  height: 100%;
  transform: rotateX(180deg);
  overflow-x: auto;
  overflow-y: hidden;
}
.MainContainer::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.DropMenuContainer {
  padding: 10px;
  width: 100%;
  transform: rotateX(180deg);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  user-select: none;
}
.DropMenuPlaceholder {
  width: 80px;
  min-width: 80px;
  max-width: 80px;
  height: 55px;
  min-height: 55px;
  max-height: 55px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  position: relative;
}
.DropMenu {
  top: 70px;
  position: absolute;
  background-color: #f1f1f1;
  padding: 5px;
  box-shadow: 0 0 2px rgb(88, 88, 88);
  border-radius: 3px;
  transition: 0.3s;
  animation: DropMenuAppears 0.3s;
}
.DropMenuItem {
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 2px 10px;
  border-bottom: 1px solid rgb(225, 225, 225);
}
.LastItem {
  border: none;
}
.IconWrapper {
  height: 22px;
  width: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 7px;
}
.DropMenuItem svg {
  height: 22px;
  width: 22px;
}
.DropMenuItem:hover {
  background-color: #cccccc;
}
@keyframes DropMenuAppears {
  0% {
    top: 50px;
    opacity: 0;
  }
  100% {
    top: 70px;
    opacity: 1;
  }
}
@media only screen and (max-width: 1000px) {
  .DropMenuPlaceholder {
    width: 70px;
    min-width: 70px;
    max-width: 70px;
  }
}
