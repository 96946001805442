.CredButtonsWrapper {
  max-height: 300px;
  border-bottom: 1px solid rgb(225, 216, 216);
  padding-bottom: 10px;
}
.CredButtons {
  display: flex;
  flex-direction: column;
}
.CredButtonsItem {
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 0 5px;
  user-select: none;
  cursor: pointer;
  border: none;
  background-color: transparent;
}
.CredButtonsItem:hover {
  background-color: rgb(198, 198, 198);
}
.CredButtonsItemIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}
.CredButtonsItemTitle {
  vertical-align: center;
  padding: 5px 0;
  max-width: 80%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@media only screen and (max-width: 800px) {
  .CredButtons {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .CredButtonsItem {
    overflow: hidden;
    padding: 5px;
    font-size: 12px;
    justify-content: center;
  }
}
